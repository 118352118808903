<template>
  <b-sidebar
    id="add-new-client-sidebar"
    :visible="isAddNewClientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-client-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ formTitle }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <!-- Client Name -->
          <validation-provider
            #default="validationContext"
            name="Client name"
            rules="required"
          >
            <b-form-group
              label="Client name"
              label-for="client-name"
            >
              <b-form-input
                id="client-name"
                v-model="client.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :readonly="!clientNameEditable"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            name="First name"
            rules=""
          >
            <b-form-group
              label="First name"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="client.user.first_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last name -->
          <validation-provider
            #default="validationContext"
            name="Last name"
            rules=""
          >
            <b-form-group
              label="Last name"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="client.user.last_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="client.user.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Job Description -->
          <b-form-group
            label="Job description"
            label-for="job-description"
          >
            <b-form-input
              id="job-description"
              v-model="client.user.job_description"
            />
          </b-form-group>

          <!-- Send reset password email -->
          <validation-provider
            #default="validationContext"
            name="Send invitation"
            rules=""
          >
            <b-form-group
              label="Send invitation"
              label-for="send-invitation"
            >
              <b-form-checkbox
                id="send-invitation"
                v-model="sendInvitation"
                value="true"
                unchecked-value="false"
              >Send invitation email
              </b-form-checkbox>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewClientSidebarActive: {
      type: Boolean,
      required: true,
    },
    formTitle: {
      type: String,
      default: 'Add New Client',
    },
    clientName: {
      type: String,
      default: '',
    },
    clientNameEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      client: {
        name: '',
        user: {
          first_name: '',
          last_name: '',
          email: '',
          job_description: '',
        },
      },
      sendInvitation: true,
      email,
      required,

      refFormObserver: null,
      getValidationState: null,
    }
  },
  watch: {
    clientName() {
      this.client.name = this.clientName
    },
  },
  created() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    this.refFormObserver = refFormObserver
    this.getValidationState = getValidationState
  },
  methods: {
    onSubmit() {
      this.$http.post('/api/v1/clients/', this.client)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Client added',
              icon: 'UserIcon',
              variant: 'success',
            },
          })
          this.$emit('update:is-add-new-client-sidebar-active', false)
          if (this.sendInvitation) {
            this.$http
              .post('/api/v1/users/reset_password/', {
                email: this.client.user.email,
                invite: true,
              })
          }
          this.$emit('refetch-data')
        })
        .catch(error => {
          const {
            email: emailError,
          } = error.response.data

          if (emailError) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Email already exists',
                icon: 'EmailIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
